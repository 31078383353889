import {BaseGraph} from "../base_graph";

class InfringementsTypeDistribution extends BaseGraph {
    constructor() {
        super('infringements_type_distribution');
    }

    setupGraph(data) {
        const keys = Object.keys(data).sort();
        const this_year = keys[1],
            last_year = keys[0];

        if (data[this_year]) {
            const $canvas = $('canvas#this_year');
            app.DASHBOARD_CORE.renderPiechart($canvas, data[this_year]);
        }

        if (data[last_year]) {
            const $canvas = $('canvas#last_year');
            app.DASHBOARD_CORE.renderPiechart($canvas, data[last_year]);
        }

        app.DASHBOARD_CORE.setLoadingState(this.GRIDSTACK_NAME, false);
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new InfringementsTypeDistribution();
    } else {

    }
});